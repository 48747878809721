.header {
  display: flex;
  justify-content: space-between;
  color: #fff3e4;
  font-size: 2rem;
  align-items: center;
  padding: 0 80px;
}
.header h1 {
  font-size: 2.5rem;
  font-family: 'Dancing Script', serif;
  letter-spacing: 3px;
}
.header a {
  margin-left: 20px;
  color: #fff3e4;
}
@media screen and (max-width: 700px) {
  .header {
    flex-direction: column;
    padding: 0;
  }
}