.image {
  overflow: hidden;
}
.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0;
}
.button button {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  font-weight: 700;
  color: #fff4e5e6;
}
.size {
  display: flex;
  transition: ease-out 0.5s;
}
.imageBackground {
  position: relative;
  background-image: url("../../public/pexels-fwstudio-33348-172289.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 70px 35px 35px 35px;
}
.imageBackground h3 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  color: #fff4e5;
  font-size: 2rem;
  margin: 0;
  font-family: 'Dancing Script', serif;
  letter-spacing: 2px;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .imageBackground {
    padding: 45px 25px 25px 25px;
  }
  .imageBackground h3 {
    font-size: 1.5rem;
  }
  .button button {
    font-size: 1.5rem;
  }
}