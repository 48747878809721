.footer {
  border-top: solid 1px black;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding-top: 20px;
  justify-content: space-evenly;
  padding-bottom: 20px;
}
.footer span {
  margin-left: 10px;
  font-weight: 600;
}
.footer a {
  text-decoration: none;
  color: black;
  transition: 1s ease;
}
.footer a:hover {
  text-decoration: underline;
}
.border {
  flex-basis: 50%;
  text-align: center;
}
.contact {
  margin-top: 100px;
  text-align: center;
  font-size: 1.5rem;
  font-family: 'Dancing Script', serif;
}
@media screen and (max-width: 800px) {
  .para {
    margin-bottom: 0;
  }
  .footer {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .footer {
    font-size: 1rem;
  }
}
