.main {
  text-align: center;
  margin: 100px auto 0 auto;
  width: 50%;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  width: 50%;
  margin: 0 auto;
}
.main h2 {
  font-size: 4rem;
  color: #00000099;
  font-family: 'Dancing Script', serif;
  letter-spacing: 5px;
}
.main h3 {
  font-size: 2.5rem;
  color: #00000099;
  background-image: url("../../public/pexels-fwstudio-33348-172289.jpg");
  display: inline-block;
  font-family: 'Dancing Script', serif;
  color: #fff3e4;
  padding: 20px;
  text-align: center;
  margin-top: 100px;
}
.main p {
  font-size: 2rem;
}
@media screen and (max-width: 1600px) {
  .main {
    width: 85%;
  }
}
@media screen and (max-width: 1000px) {
  .text {
    flex-direction: column;
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  .main p {
    font-size: 1.5rem;
  }
  .main h2 {
    font-size: 3rem;
  }
}
@media screen and (max-height: 1000px) {
  .main p {
    font-size: 1.5rem;
  }
  .main h2 {
    font-size: 3rem;
  }
}