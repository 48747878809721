.images {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 90%;
  align-items: flex-start;
  padding: 50px 0;
}
.second {
  align-self: flex-end;
  flex-basis: 30%;
  font-size: 2rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.49) 0px 10px 20px, rgba(0, 0, 0, 0.53) 0px 6px 6px;
}
.first {
  flex-basis: 30%;
  font-size: 2rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.49) 0px 10px 20px, rgba(0, 0, 0, 0.53) 0px 6px 6px;
}
.third {
  flex-basis: 30%;
  font-size: 2rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.49) 0px 10px 20px, rgba(0, 0, 0, 0.53) 0px 6px 6px;
}
.fourth {
  align-self: flex-end;
  flex-basis: 30%;
  font-size: 2rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.49) 0px 10px 20px, rgba(0, 0, 0, 0.53) 0px 6px 6px;
  display: none;
}
.para {
  border: 5px solid hsl(36, 70%, 28%, 80%);
  padding: 20px 0;
  margin: 0;
}
@media screen and (max-width: 1800px) and (min-width: 1201px) {
  .images {
    flex-wrap: wrap;
    height: auto;
    justify-content: space-evenly;
  }
  .fourth {
    display: block;
    flex-basis: 31vw;
    margin-top: 40px;
  }
  .second {
    display: none;
  }
  .first {
    flex-basis: 31vw;
  }
  .third {
    flex-basis: 31vw;
    margin-top: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .images {
    width: 70%;
    height: auto;
    flex-direction: column;
    gap: 50px;
  }
  .fourth {
    display: block;
  }
  .second {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .images {
    width: 85%;
  }
}
@media screen and (max-width: 500px) {
  .images p {
    font-size: 1.5rem;
  }
}
@media screen and (max-height: 1000px) {
  .first, .second, .third, .fourth {
    font-size: 1.5rem;
  }
}