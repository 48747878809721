.head {
  width: 80%;
  margin: 40px auto;
  background-image: url("../../public/pexels-fwstudio-33348-164005.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  box-shadow: 17px 21px 13px 0px rgba(0, 0, 0, 0.37);
  position: relative;
}
.head p {
  display: flex;
}
.title {
  width: 30%;
  min-width: 400px;
  text-align: left;
  position: absolute;
  top: 30%;
  left: 20%;
}
.head h1 {
  font-size: 4rem;
  font-weight: 700;
  color: #fff3e4;
  margin-bottom: 20px;
  letter-spacing: 2px;
}
.ankar {
  padding: 10px;
  border: solid 1px #fff3e4;
  margin-right: 20px;
  transition: 1s ease;
}
.ankar a {
  text-decoration: none;
  color: #fff3e4;
  font-size: 1.5rem;
  display: inline-block;
  padding: 10px;
  margin: -10px;
}
.ankar:hover {
  background-color: #fff3e4;
}
.ankar a:hover {
  color: black;
}
.opacity {
  opacity: 40%;
}
@media screen and (max-width: 700px) {
  .title {
    left: 50%;
    transform: translateX(-50%);
  }
  .head {
    width: 85%;
  }
  .head h1 {
    font-size: 3.5rem;
    margin: 20px 0;
  }
}
@media screen and (max-width: 900px) {
  .head p {
    flex-direction: column;
    gap: 10px;
  }
}
@media screen and (max-width: 500px) {
  .head {
    width: 100%;
    height: 100vh;
    margin: 0;
  }
  .head h1 {
    font-size: 2.5rem;
  }
  .title {
    min-width: 90%;
  }
  .ankar {
    width: fit-content;
    text-align: center;
  }
  .ankar a {
    font-size: 1.3rem;
  }
}
@media screen and (max-height: 1000px) {
  .title {
    width: 40%;
  }
  .head h1 {
    font-size: 3rem;
  }
}
