.ending {
  background-image: url("../../public/pexels-fwstudio-33348-172289.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40px;
  color: #fff3e4;
}
.ending span {
  font-weight: 700;
}