.image-dot {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 7px;
  z-index: 997;
  width: 25%;
  height: 25%;
}
.dot {
  flex-basis: 7%;
  height: 7%;
  border: solid #fff 1px;
  background-color: transparent;
  border-radius: 50%;
}
.active {
  background-color: #fff;
  flex-basis: 10%;
  height: 10%;
}
